import Container from 'index.style';
import React from 'react';
import {
  BannerImg,
  LeftBlock,
  LeftBlockHeader,
  LeftBlockTag,
  LeftBlockText,
  MainBannerBox,
  RightBlock,
  Highlight,
  ButtonBox,
} from './MainBanner.style';
import MainBannerImg from 'assets/img/MainBanner.webp';
import { default as ArrowRightUpIcon } from 'assets/img/ArrowRightUpIcon.svg';
import { Button } from 'components/common/Button';

export const MainBanner = () => {
  return (
    <Container>
      <MainBannerBox>
        <LeftBlock>
          <LeftBlockText>
            <LeftBlockTag>
              <p>Полностью прозрачные условия</p>
              <img src={ArrowRightUpIcon} alt='iconArrow' />
            </LeftBlockTag>
            <LeftBlockHeader>
  Легкий доступ к капиталу для <Highlight>предпринимателей</Highlight>
  <br />
  Новые возможности для <Highlight>инвесторов</Highlight>
</LeftBlockHeader>

          </LeftBlockText>
          <ButtonBox>
            <Button text='Получить финансирование' />
            <Button text='Получить бесплатное руководство' className='white' />
          </ButtonBox>
        </LeftBlock>
        <RightBlock>
          <BannerImg src={MainBannerImg} alt='banner' />
        </RightBlock>
      </MainBannerBox>
    </Container>
  );
};
