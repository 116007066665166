import React, { useState, useEffect } from 'react';
import Container from 'index.style';
import { Box, Card, Number, Title } from './NumberSection.style';
import CountUp from 'react-countup';

const numberSectionTexts = {
  projectLight: 'Инвестировано',
  clientsLight: 'Проектов',
  teamSize: 'Выплачено дивидендов',
  yearsMarket: 'Инвесторов',
  plus: ' +',
  tenge: ' ₸'
};

const PROJECT_NUMBER = 431024614;
const CLIENTS_NUMBER = 141;
const TEAM_SIZE_NUMBER = 21163413;
const YEARS_MARKET_NUMBER = 450;
const START_NUMBER = 0;
const SECTION_START_ID = '#number-section';

const DEFAULT_START_COUNT = 0;
const DEFAULT_DURATION_COUNT = 6;
const DEFAULT_DELAY_COUNT = 0;
const DEFAULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD_COUNT = 0.9;

const options = {
  rootMargin: DEFAULT_ROOT_MARGIN,
  threshold: DEFAULT_THRESHOLD_COUNT,
};

export const LightNumberSection = () => {
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCounterOn(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const target = document.querySelector(SECTION_START_ID);
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, []);

  return (
            <Container>
                    <Box id='number-section'>

        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? PROJECT_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              suffix={numberSectionTexts.tenge}
              redraw={false}
            />
          </Number>
          <Title className='light'>{numberSectionTexts.projectLight}</Title>
        </Card>
        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? CLIENTS_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              redraw={false}
              suffix={numberSectionTexts.plus}
            />
          </Number>
          <Title className='light'>{numberSectionTexts.clientsLight}</Title>
        </Card>
        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? TEAM_SIZE_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              suffix={numberSectionTexts.tenge}
              redraw={false}
            />
          </Number>
          <Title className='light'>{numberSectionTexts.teamSize}</Title>
        </Card>
        <Card>
          <Number>
            <CountUp
              start={DEFAULT_START_COUNT}
              end={counterOn ? YEARS_MARKET_NUMBER : START_NUMBER}
              duration={DEFAULT_DURATION_COUNT}
              delay={DEFAULT_DELAY_COUNT}
              suffix={numberSectionTexts.plus}
              redraw={false}

            />
          </Number>
          <Title className='light'>{numberSectionTexts.yearsMarket}</Title>
        </Card>
      </Box>
      </Container>
  );
};
