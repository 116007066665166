import { styled } from 'styled-components';
import colors from 'global_styling/colors';
import { ReactComponent as Instagram } from 'assets/img/IconInstagram.svg';
import { ReactComponent as Youtube } from 'assets/img/IconYoutube.svg';
import { ReactComponent as Telegram } from 'assets/img/IconTelegram.svg';
// import { ReactComponent as Twitter } from 'assets/img/IconTwitter.svg';
// import { ReactComponent as Facebook } from 'assets/img/IconFacebook.svg';
import { Link } from 'react-router-dom';

const FooterBox = styled.div`
  width: 100%;
background: #181B3E;
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 42px 0px;
  @media (max-width: 510px) {
    flex-direction: column;
    height: 100%;
    .first {
      margin-top: 120px;
    }
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 286px;
  @media (max-width: 900px) {
    max-width: 208px;
  }
  @media (max-width: 510px) {
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    max-width: 100%;
  }
  img {
    width: 116px;
    height: 32px;
  }
  h4 {
    color: #EAEDFF;
    font-size: 18px;
    font-weight: 700;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #EAEDFF;
    font-size: 14px;
    font-weight: 400;
    li {
      cursor: pointer;
    }
    li:hover {
      color: ${colors.TEAL};
    }
  }
`;

const InfoDescription = styled.p`
color: #EAEDFF;
font-size: 12px;
  font-weight: 400;
`;

const Line = styled.div`
  border: 1px solid ${colors.BORDER_GREY};
`;

const MiniFooter = styled.div`
  display: flex;
  padding: 24px 0px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 510px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const NetworkBox = styled.div`
  display: flex;
  gap: 22px;
`;
const Copyright = styled.p`
color: #EAEDFF;
font-size: 12px;
  font-weight: 400;
`;

const IconBase = (Component: React.ComponentType) => styled(Component)`
  cursor: pointer;

  rect {
    fill: ${colors.LIGHT_GRAY};
    fill-opacity: 1;
  }

  path {
    fill: ${colors.GRAY};
    opacity: 0.2;
  }

  &:hover {
    rect {
      fill: ${colors.TEAL};
      fill-opacity: 1;
    }

    path {
      fill: ${colors.WHITE};
      opacity: 1;
    }
    path:nth-child(3) {
      fill: ${colors.TEAL};
    }
  }
`;

const IconInstagram = IconBase(Instagram);
const IconYoutube = IconBase(Youtube);
const IconTelegram = IconBase(Telegram);
// const IconFacebook = IconBase(Facebook);
// const IconTwitter = IconBase(Twitter);

const StyledLink = styled(Link)`
color: #EAEDFF;
text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    color: ${colors.TEAL};
  }
`;

export {
  FooterBox,
  InfoBox,
  InfoDescription,
  BoxRow,
  MiniFooter,
  NetworkBox,
  Copyright,
  Line,
  IconInstagram,
  IconYoutube,
  IconTelegram,
  // IconFacebook,
  // IconTwitter,
  StyledLink,
};
