import styled from 'styled-components';

export const MainBannerBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 136px;
  @media (max-width: 1120px) {
    margin-top: 50px;
  }
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  max-width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LeftBlockText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

export const LeftBlockTag = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: #f8f9fd;
  width: 100%;
  max-width: 292px;
  p {
    color: #5784f7;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const LeftBlockHeader = styled.h1`
  color: #292d32;
  font-size: 48px;
  font-weight: 700;
  @media (max-width: 1120px) {
    font-size: 24px;
    max-width: 440px;
  }
  @media (max-width: 767px) {
  }
`;

export const Highlight = styled.span`
  color: #5784f7;
`;

export const RightBlock = styled.div`
  display: flex;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const BannerImg = styled.img`
  max-width: 500px;
  height: 500px;
  @media (max-width: 1120px) {
    max-width: 400px;
    height: 400px;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 1120px) {
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
