import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainScreen from 'screens/MainScreen/MainScreen';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<MainScreen />} />
      </Routes>
    </div>
  );
}

export default App;
