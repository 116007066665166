import React from 'react';
import { InputBox, Inputt, ErrorMessage } from './Input.style';

enum InputTextType {
  TEXT = 'text',
  PHONE = 'tel',
  EMAIL = 'email',
}

interface InputProps {
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  type?: string;
  placeholder?: string;
}

export const Input = ({
  type = InputTextType.TEXT,
  placeholder,
  value,
  onChange,
  error,
}: InputProps) => {
  const hasError = Boolean(error);
  return (
    <>
      <InputBox>
        <Inputt
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          $hasError={hasError}
        />
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </InputBox>
    </>
  );
};
