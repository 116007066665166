import {
    FooterBox,
    InfoBox,
    InfoDescription,
    BoxRow,
    MiniFooter,
    NetworkBox,
    Copyright,
    Line,
    IconInstagram,
    IconYoutube,
    IconTelegram,
    // IconFacebook,
    // IconTwitter,
  } from './Footer.style';
  import Container from 'index.style';
  import { default as LogoLendKz } from 'assets/img/LogoLendKz.png';
  import { useTranslation } from 'react-i18next';
  import ScrollToTopLink from 'utils/ScrollToTopLink';
  
  export const Footer = () => {
    const { t } = useTranslation();
  
    return (
      <FooterBox>
        <Container>
          <BoxRow>
            <InfoBox className='first'>
              <img src={LogoLendKz} alt='logo' />
              <InfoDescription>Инвестиционная деятельность неразрывно связана с высокими финансовыми рисками, включая возможные задержки выплат и частичную или полную утрату инвестированных средств. Важно отметить, что историческая доходность вложений не является обещанием будущих результатов или доходов. Рекомендуется вкладывать только средства, потерю которых вы готовы позволить себе.</InfoDescription>
              <Copyright>Lend.kz © 2024 Все права защищены.</Copyright>
            </InfoBox>
            <InfoBox>
              <h4>Разделы</h4>
              <ul>
                <li>
                  <ScrollToTopLink to='/'>{t('footer.menuOne')}</ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to='/business'>{t('footer.menuTwo')}</ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to='/investor'>{t('footer.menuThree')}</ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to='/agent'>{t('footer.menuFour')}</ScrollToTopLink>
                </li>
              </ul>
            </InfoBox>
            <InfoBox>
              <h4>Информация</h4>
              <ul>
                {/* <li>{t('footer.menuFive')}</li>
                <li>{t('footer.menuSix')}</li> */}
                <li>
                  <ScrollToTopLink to='/contact'>Контакты</ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to='/data_agreement'>Соглашение о данных</ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to='/user_agreement'>Пользовательское соглашение</ScrollToTopLink>
                </li>
              </ul>
            </InfoBox>
          </BoxRow>
        </Container>
      </FooterBox>
    );
  };
  