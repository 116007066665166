import { useState, useEffect } from 'react';
import {
  Menu,
  LogoCompany,
  DropdownContainer,
  DropdownContent,
  StyledLink,
  Arrow,
  BoxSwitcher,
  ButtonBox,
  BoxMenu,
} from './Header.style';
import { default as LogoLendKz } from 'assets/img/LogoLendKz.svg';
import { default as MenuOpen } from 'assets/img/MenuOpen.svg';
import { default as MenuClose } from 'assets/img/MenuClose.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'index.style';
import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { Button } from 'components/common/Button';

export const Header = () => {
  const currentScreen = useLocation();
  const currentPath = currentScreen.pathname;
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const handleDropdownToggle = (menuName: string) => {
    setActiveDropdown((prev) => (prev === menuName ? null : menuName));
  };

  const handleLinkClick = (event: React.MouseEvent, path: string) => {
    event.preventDefault();

    const [pathname, hash] = path.split('#');

    if (currentPath !== pathname) {
      navigate(pathname);
    }

    if (hash) {
      setTimeout(() => {
        const targetElement = document.getElementById(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }

    setMenuOpen(false);
    setActiveDropdown(null);
  };

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : '';
  }, [menuOpen]);

  return (
    <Container>
      <Menu>
        <BoxMenu>
          <LogoCompany src={LogoLendKz} loading='eager' alt='Logo' />
          <ul className={menuOpen ? 'menu active' : 'menu'}>
            <li>
              <DropdownContainer>
                <StyledLink
                  to='#'
                  onClick={() => handleDropdownToggle('business')}
                  className={activeDropdown === 'business' ? 'current' : 'decoration'}>
                  Бизнесу <Arrow data-is-open={activeDropdown === 'business'} />
                </StyledLink>
                {activeDropdown === 'business' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/business/option1')}
                          to='/business/option1'>
                          Главные возможности
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/business/option2')}
                          to='/business/option2'>
                          Истории успеха
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/business/option3')}
                          to='/business/option3'>
                          Кому подходит?
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/business/option4')}
                          to='/business/option4'>
                          Как презентовать свой проект?
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/business/option5')}
                          to='/business/option5'>
                          Инструменты финансирования{' '}
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>
            <li>
              <DropdownContainer>
                <StyledLink
                  to='#'
                  onClick={() => handleDropdownToggle('investors')}
                  className={activeDropdown === 'investors' ? 'current' : 'decoration'}>
                  Инвесторам <Arrow data-is-open={activeDropdown === 'investors'} />
                </StyledLink>
                {activeDropdown === 'investors' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/investors/option1')}
                          to='/investors/option1'>
                          Главные возможности
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/investors/option2')}
                          to='/investors/option2'>
                          Истории успеха
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/investors/option3')}
                          to='/investors/option3'>
                          Кому подходит?
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/investors/option4')}
                          to='/investors/option4'>
                          Как презентовать свой проект?
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/investors/option5')}
                          to='/investors/option5'>
                          Инструменты финансирования{' '}
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>
            <li>
              <DropdownContainer>
                <StyledLink
                  to='#'
                  onClick={() => handleDropdownToggle('broker')}
                  className={activeDropdown === 'broker' ? 'current' : 'decoration'}>
                  Брокерам и фондам <Arrow data-is-open={activeDropdown === 'broker'} />
                </StyledLink>
                {activeDropdown === 'broker' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/broker/option1')}
                          to='/broker/option1'>
                          Главные возможности
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/broker/option2')}
                          to='/broker/option2'>
                          Истории успеха
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/broker/option3')}
                          to='/broker/option3'>
                          Кому подходит?
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/broker/option4')}
                          to='/broker/option4'>
                          Как презентовать свой проект?
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/broker/option5')}
                          to='/broker/option5'>
                          Инструменты финансирования{' '}
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>
            <li>
              <StyledLink
                onClick={(e) => handleLinkClick(e, '/#projects')}
                className={currentPath === '/#projects' ? 'current' : 'decoration'}
                to='/#projects'>
                Проекты
              </StyledLink>
            </li>
            <li>
              <DropdownContainer>
                <StyledLink
                  to='#'
                  onClick={() => handleDropdownToggle('information')}
                  className={activeDropdown === 'information' ? 'current' : 'decoration'}>
                  Информация <Arrow data-is-open={activeDropdown === 'information'} />
                </StyledLink>
                {activeDropdown === 'information' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/info/option1')}
                          to='/info/option1'>
                          Соглашение о данных
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '/info/option2')}
                          to='/info/option2'>
                          Пользовательское соглашение
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>
          </ul>
        </BoxMenu>

        <BoxSwitcher>
          <LanguageSwitcher />
          <ButtonBox className='buttons'>
            <a href={'212'} rel='noopener noreferrer'>
              <Button text={'Перейти на платформу'} />
            </a>
          </ButtonBox>
        </BoxSwitcher>
        <div onClick={() => setMenuOpen(!menuOpen)} className='mobile_btn'>
          {menuOpen ? (
            <img src={MenuClose} alt='icon' width={24} height={24} />
          ) : (
            <img src={MenuOpen} alt='icon' width={24} height={24} />
          )}
        </div>
      </Menu>
    </Container>
  );
};
