import { CatalogProjects } from 'components/CatalogProjects';
import { FeedbackBlock } from 'components/FeedbackBlock';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { MainAdvantages } from 'components/MainAdvantages';
import { MainBanner } from 'components/MainBanner';
import { LightNumberSection } from 'components/NumberSection';
import React from 'react';

const MainScreen = () => {
  return (
    <>
      <Header />
      <MainBanner />
      <MainAdvantages />
      <CatalogProjects />
      <LightNumberSection />
      <FeedbackBlock />
      <Footer />
    </>
  );
};

export default MainScreen;
