import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow, DropdownContainer, DropdownContent } from './LanguageSwitcher.style';
import useClickOutside from 'utils/useClickOutside';

export const LanguageSwitcher = () => {
  const [selectLanguage, setSelectLanguage] = useState(false);
  const [language, setLanguage] = useState('RU');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLanguage(savedLanguage.toUpperCase());
    }
  }, [i18n]);

  const changeLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage.toUpperCase());
    localStorage.setItem('language', newLanguage);
    setSelectLanguage(false);
  };

  const handleToggleDropdown = () => {
    setSelectLanguage(!selectLanguage);
  };

  useClickOutside(dropdownRef, () => {
    setSelectLanguage(false);
  });

  return (
    <DropdownContainer ref={dropdownRef}>
      <ul>
        <li onClick={handleToggleDropdown}>
          {language}
          <Arrow data-is-open={selectLanguage} />
        </li>
        <DropdownContent className={selectLanguage ? 'open' : ''}>
          <ul>
            <li>
              <button onClick={() => changeLanguage('ru')}>RU</button>
            </li>
            <li>
              <button onClick={() => changeLanguage('kk')}>KK</button>
            </li>
            <li>
              <button onClick={() => changeLanguage('en')}>EN</button>
            </li>
          </ul>
        </DropdownContent>
      </ul>
    </DropdownContainer>
  );
};
