import React, { useEffect, useState } from 'react';
import { CatalogProjectsBox, ProjectCard, CardsGrid, ProjectCardContent, ButtonBox, ParticipateButton } from './CatalogProjects.style';
import Container from 'index.style';
import axios from 'axios';
import { Button } from 'components/common/Button';
import { default as ArrowRightUpIcon } from 'assets/img/ArrowRightUpIcon.svg';

type Project = {
    requestedAmount: number | null;
    requestRate: number | null;
    requestPeriod: number | null;
    fundingGoal: { text_ru: string } | null;
    collateral: any | null;
    industry: { text_ru: string } | null;
    product: { text_ru: string } | null;
  };
  

export const CatalogProjects = () => {
    const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    // Загрузка данных из API
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://qa.moneylink.kz/api/v1/public/landing/request_cards/full');
        setProjects(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <CatalogProjectsBox>
      <Container>
        <h2>Каталог проектов</h2>
        <CardsGrid>
          {projects.map((project, index) => (
            <ProjectCard key={index}>
  <ProjectCardContent>
    <div>
      <p>
        <strong>Запрошенная сумма:</strong> {project.requestedAmount?.toLocaleString('ru-RU')} ₸
      </p>
    </div>
    <div>
      <p>
        <strong>Процентная ставка:</strong> {project.requestRate ? `${project.requestRate}%` : 'Не указанно'}
      </p>
    </div>
  </ProjectCardContent>
  <ProjectCardContent>
    <div>
      <p>
        <strong>Цель займа:</strong> {project.fundingGoal?.text_ru || 'Не указано'}
      </p>
    </div>
    <div>
      <p>
        <strong>Индустрия:</strong> {project.industry?.text_ru || 'Не указано'}
      </p>
    </div>
  </ProjectCardContent>
  <ParticipateButton>
              <p>Участвовать</p>
              <img src={ArrowRightUpIcon} alt='iconArrow' />
  </ParticipateButton>
</ProjectCard>

          ))}
        </CardsGrid>
        <ButtonBox>
        <Button text='Перейти на платформу'></Button>
        </ButtonBox>
      </Container>
    </CatalogProjectsBox>
  );
};
