import React, { useState } from 'react';
import styled from 'styled-components';

import Container from 'index.style';
import { Card, CardGrid, Tab, Tabs, Title, ToggleBusiness, ToggleInvestor } from './MainAdvantages.style';
import { default as IconLaptopCloud } from 'assets/img/IconLaptopCloud.svg';
import { default as IconLegalDocument } from 'assets/img/IconLegalDocument.svg';
import { default as IconQuran } from 'assets/img/IconQuran.svg';
import { default as IconAnalytics } from 'assets/img/IconAnalytics.svg';
import { default as IconPayments } from 'assets/img/IconPayments.svg';
import { default as IconSpeed } from 'assets/img/IconSpeed.svg';
import { default as IconEnergy } from 'assets/img/IconEnergy.svg';
import { default as IconApproval } from 'assets/img/IconApproval.svg';
import { default as IconInternet } from 'assets/img/IconInternet.svg';

const data = {
    business: [
      { title: 'Скорость сделок', description: 'Привлекать деньги на p2p платформе намного быстрее, чем в банке', icon: IconSpeed },
      { title: 'Гибкость', description: 'Платформа дает возможность использовать любые инструменты финансирования', icon: IconEnergy },
      { title: 'Свои условия', description: 'Ты можешь предлагать условия финансирования, выгодные тебе', icon: IconApproval },
      { title: 'Полностью онлайн', description: 'Не нужно никуда идти - совершай сделки на платформе', icon: IconInternet },
      { title: 'Электронные документы', description: 'Счета, акты, сверки - все формируется системой в электронном виде', icon: IconLaptopCloud },
      { title: 'Контроль над платежами', description: 'Ты всегда знаешь даты и суммы платежей', icon: IconPayments },
      { title: 'Минимум усилий', description: 'Не нужно писать планы и обоснования - платформа соберет все данные о тебе и представит инвестору', icon: IconApproval },
      { title: 'Исламские инструменты', description: 'Платформа дает возможность заключать сделки инвестирования в соответствии с требованиями Шариата', icon: IconQuran },
      { title: 'Юридическая значимость', description: 'Все сделки совершаются с применением ЭЦП в полном соответствии законодательству', icon: IconLegalDocument },
    ],
    investor: [
      { title: 'Выбор проектов и бизнесов', description: 'Выбирай проекты и бизнесы, исходя из своих целей инвестора', icon: IconSpeed },
      { title: 'Электронные документы', description: 'Счета, акты, сверки - все формируется системой в электронном виде', icon: IconLaptopCloud },
      { title: 'Полностью онлайн', description: 'Не нужно никуда идти - совершай сделки на платформе', icon: IconInternet },
      { title: 'Свои условия', description: 'Ты можешь предлагать условия финансирования, выгодные тебе', icon: IconApproval },
      { title: 'Гибкость', description: 'Платформа дает возможность использовать любые инструменты финансирования', icon: IconEnergy },
      { title: 'Юридическая значимость', description: 'Все сделки совершаются с применением ЭЦП в полном соответствии законодательству', icon: IconLegalDocument },
      { title: 'Максимум анализа', description: 'Мы собрали все доступные данные о заемщиках, и даже больше', icon: IconAnalytics },
      { title: 'Контроль над платежами', description: 'Ты всегда знаешь даты и суммы платежей', icon: IconPayments },
      { title: 'Исламские инструменты', description: 'Платформа дает возможность заключать сделки инвестирования в соответствии с требованиями Шариата', icon: IconQuran },
    ],
  };
  

export const MainAdvantages = () => {
  const [activeTab, setActiveTab] = useState<'business' | 'investor'>('business');
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };



  return (
    <Container>
      <Title>Основные преимущества</Title>
      <Tabs>
        <Tab active={activeTab === 'business'} onClick={() => setActiveTab('business')}>
          <ToggleBusiness active={activeTab === 'business'}/>
          Бизнес
        </Tab>
        <Tab active={activeTab === 'investor'} onClick={() => setActiveTab('investor')}>
          <ToggleInvestor  active={activeTab === 'investor'}/>
          Инвестор
        </Tab>
      </Tabs>
      <CardGrid>
        {data[activeTab].map((item, index) => (
          <Card
            key={index}
            hovered={hoveredCard === index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredCard !== index && <img src={item.icon} alt={item.title} className="card-icon" />}

            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Card>
        ))}
      </CardGrid>
    </Container>
  );
};
