import Container from 'index.style';
import React, { useState } from 'react';
import {
  ErrorMessage,
  FeedbackBlockBox,
  InputCont,
  InputMaskCustom,
  InputMaskCustomBox,
  LeftBlock,
  LeftBlockText,
  RightBlock,
  RightBlockDesc,
  RightBlockForm,
  RightBlockHeader,
  TextareaCustom,
} from './FeedbackBlock.style';
import { Button } from 'components/common/Button';
import { isValidPhone } from 'utils/isValidPhone';
import { useTranslation } from 'react-i18next';
import { sendFormFeedback } from 'components/api/apiService';
import { Input } from 'components/common/Input';

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const FeedbackBlock = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [inputErrors, setInputErrors] = useState({
    nameError: '',
    phoneError: '',
    emailError: '',
    messageError: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { t } = useTranslation();

  const validateForm = (name: string, phone: string, email: string, message: string) => {
    const isNameValid = name !== '';
    const isMessageValid = message !== '';
    const isPhoneValid = isValidPhone(phone);
    const isEmailValid = isValidEmail(email);

    const errors = {
      nameError: isNameValid ? '' : t('contactForm.errorName'),
      phoneError: isPhoneValid ? '' : t('contactForm.errorPhone'),
      emailError: isEmailValid ? '' : t('contactForm.errorEmail'),
      messageError: isMessageValid ? '' : t('contactForm.errorText'),
    };

    return { isValid: isNameValid && isMessageValid && isPhoneValid && isEmailValid, errors };
  };

  const formatPhone = (phone: string) => phone.replace(/\D/g, '');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);
    const isPhoneValid = isValidPhone(value);
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      phoneError: isPhoneValid ? '' : t('contactForm.errorPhone'),
    }));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);

    const isEmailValidValue = isValidEmail(value);
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      emailError: isEmailValidValue ? '' : t('contactForm.errorEmail'),
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { isValid, errors } = validateForm(name, phone, email, message);
    setInputErrors(errors);

    if (isValid) {
      const formattedPhone = formatPhone(phone);
      const payload = {
        name,
        email,
        message,
        phone: formattedPhone,
      };

      try {
        const response = await sendFormFeedback(payload);
        if (response.status === 200) {
          setIsFormSubmitted(true);
        } else {
          console.log(`Unexpected status code: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while sending the form:', error);
      }
    }
  };

  return (
    <Container>
      <FeedbackBlockBox>
        <LeftBlock>
          <LeftBlockText>
            <h3>Новые возможности работы с капиталом</h3>
            <p>
              P2P-платформа, которая соединяет предпринимателей с инвесторами для достижения новых высот. Откройте для
              себя новый формат финансирования и начните рост уже сегодня.
            </p>
          </LeftBlockText>
          <Button text="Зарегистрироваться" className="white" />
        </LeftBlock>
        {isFormSubmitted ? (
          <></>
        ) : (
          <RightBlock>
            <RightBlockHeader>СВЯЗАТЬСЯ С НАМИ</RightBlockHeader>
            <RightBlockDesc>Контакты</RightBlockDesc>
            <RightBlockForm onSubmit={handleFormSubmit}>
              <Input
                type="text"
                value={name}
                placeholder={'Как вас зовут'}
                onChange={handleNameChange}
                error={inputErrors.nameError}
              />
              <InputCont>
                <Input
                  type="email"
                  value={email}
                  placeholder={'Ваш email'}
                  onChange={handleEmailChange}
                  error={inputErrors.emailError}
                />
                <InputMaskCustomBox>
                  <InputMaskCustom
                    mask="+7 (999) 999-99-99"
                    maskChar={null}
                    value={phone}
                    placeholder={'Ваш телефон'}
                    onChange={handlePhoneChange}
                    className={inputErrors.phoneError ? 'input-error' : ''}
                  />
                  {inputErrors.phoneError && <ErrorMessage>{inputErrors.phoneError}</ErrorMessage>}
                </InputMaskCustomBox>
              </InputCont>

              <TextareaCustom
                value={message}
                placeholder={'Ваш запрос'}
                onChange={handleMessageChange}
                className={inputErrors.messageError ? 'input-error' : ''}
              />
              {inputErrors.messageError && <ErrorMessage>{inputErrors.messageError}</ErrorMessage>}
              <Button text="Отправить" type="submit" />
            </RightBlockForm>
            </RightBlock>
        )}
      </FeedbackBlockBox>
    </Container>
  );
};
