import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';

interface InputProps {
  $hasError?: boolean;
}

const Inputt = styled.input<InputProps>`
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
font-weight: 400;
line-height: 120%;
  background: #181B3E;
  &:focus {
    outline: none;
    border-color: #fff; /* Замените на нужный цвет, если требуется */
  }
  ${({ $hasError: hasError }) =>
    hasError
      ? css`
          border: 1px solid ${colors.WARNING};
        `
      : ''}
`;

const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

const InputBox = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
`;

export { InputBox, Inputt, ErrorMessage };

