import styled from "styled-components";

import { ReactComponent as ToggleBusinessIcon } from 'assets/img/ToggleBusiness.svg';
import { ReactComponent as ToggleInvestorIcon } from 'assets/img/ToggleInvestor.svg';


// Типы для пропсов Tab
interface TabProps {
    active: boolean;
  }
  
export  const Title = styled.h2`
    color: #292D32;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 50px;
    margin-top: 150px;
    text-align: center;
    @media (max-width: 1120px) {
    margin-top: 50px;
  }
  `;
  
  export  const Tabs = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    gap: 24px;
  `;
  
  export const Tab = styled.div<TabProps>`
  display: flex;
  padding: 0px 12px 12px 12px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: color 0.3s;
  border-bottom: ${(props) => (props.active ? '2px solid #377DFF' : '2px solid #F0F3FF')};
  color: ${(props) => (props.active ? '#377DFF' : '#292D32')}; /* Цвет текста */
  font-size: 20px;

  &:hover {
    color: #377DFF;
    border-bottom: 2px solid #377DFF;
  }
  &:hover path {
    stroke: #377DFF; /* Цвет при наведении */
  }
`;

  
  
  
  export  const CardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 50px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `;
  
  export const Card = styled.div<{ hovered: boolean }>`
  padding: 20px;
  background: ${(props) => (props.hovered ? '#5784F7' : '#FFFFFF')}; /* Плавная смена фона */
  color: ${(props) => (props.hovered ? '#FFFFFF' : '#292D32')}; /* Плавная смена цвета текста */
  border: 1px solid ${(props) => (props.hovered ? '#5784F7' : '#F0F3FF')};
  border-radius: 8px;
  text-align: center;
  transition: 
    transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), /* Более плавная анимация увеличения */
    background 0.4s cubic-bezier(0.4, 0, 0.2, 1), 
    color 0.4s cubic-bezier(0.4, 0, 0.2, 1), 
    box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1), 
    border-color 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;

  @media (max-width: 767px) {
    padding: 10px;
  }

  h3 {
    font-size: 18px;
    margin: 10px 0;
    @media (max-width: 767px) {
      font-size: 16px;
      margin: 4px 0;
    }
  }

  p {
    font-size: 14px;
    display: ${(props) => (props.hovered ? 'block' : 'none')};
  }

  .card-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: ${(props) => (props.hovered ? '0' : '1')};
    transform: ${(props) => (props.hovered ? 'scale(0.9)' : 'scale(1)')}; /* Менее резкое уменьшение */
  }

  &:hover {
    transform: scale(1.03); /* Легкое увеличение */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;


const IconBase = (Component: React.ComponentType<{ className?: string }>) => styled(Component)<{ active?: boolean }>`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    stroke: ${(props) => (props.active ? '#377DFF' : '#292D32')}; /* Учитываем состояние active */
    transition: stroke 0.3s ease;
  }

  &:hover path {
    stroke: #377DFF; /* Цвет при наведении */
  }
`;


export const ToggleBusiness = IconBase(ToggleBusinessIcon);
export const ToggleInvestor = IconBase(ToggleInvestorIcon);
