import { styled } from 'styled-components';

const ButtonStyle = styled.button`
  border-radius: 6px;
  background: #5784f7;
  padding: 12px 16px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  border: none;
  font-weight: 600;
  &:hover {
    background: #316bff;
    box-shadow: 0px 0px 16px 0px rgba(87, 132, 247, 0.39);
  }
  &.white {
    border: none;
    color: #5784f7;
    background: #f0f3ff;
    &:hover {
      background: #5784f7;
      box-shadow: 0px 0px 16px 0px rgba(87, 132, 247, 0.39);
      color: #fff;
    }
  }

  &.mobile {
    font-size: 22px;
  }

  &.yellow {
    border: none;
    background: #f8b801;
    color: #fff;
    padding: 16px 38px;
    &:hover {
      background: #ffce41;
    }
  }
`;

export { ButtonStyle };
