import styled from "styled-components";

export const CatalogProjectsBox = styled.div`
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  align-self: stretch;
  background: #F8F9FD;

  h2 {
    color: #292D32;
text-align: center;
font-size: 32px;
font-weight: 700;
    margin-bottom: 50px;
  }
`;

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  margin-bottom: 50px;
`;

export const ProjectCard = styled.div.attrs(() => ({
    role: 'presentation', // пример, если нужно
  }))`
    background: #FFF;
    border-radius: 6px;
    cursor: pointer;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    transition: box-shadow 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-height: 250px; /* Задаем минимальную высоту */
  
    &:hover {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }
  
    p {
      color: #292D32;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
  
      strong {
        color: #808185;
        font-size: 16px;
        font-weight: 400;
      }
    }
  `;


export const ParticipateButton = styled.button`
  margin: auto 0 0 auto; /* Прижимает кнопку вправо и вниз */
  display: flex;
  padding: 4px 12px;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border: none;
  max-width: 138px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  background: rgba(87, 132, 247, 0.15);
  opacity: 0; /* Скрыта по умолчанию */
  transition: opacity 0.3s ease; /* Плавное появление */
  p {
    color: #5784f7;
    font-size: 16px;
    font-weight: 600;
  }

  ${ProjectCard}:hover & {
    opacity: 1; /* Появляется при наведении */
  }
`;



export const ProjectCardContent = styled.div`
display: flex;
gap: 24px;
div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
`

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  margin: 0; 
`;

