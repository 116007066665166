import styled from "styled-components";
import colors from 'global_styling/colors';
import InputMask from 'react-input-mask';


export const FeedbackBlockBox = styled.div`
display: flex;
align-items: center;
align-self: stretch;
margin: 150px 0px;
@media (max-width: 1120px) {
    flex-direction: column;
    margin: 50px 0px;
    width: 100%;
}
`

export const LeftBlock = styled.div`
display: flex;
flex-direction: column;
width: 50%;
padding: 50px;
background: #5784F7;
justify-content: center;
gap: 24px;
max-height: 546px;
min-height: 546px;
@media (max-width: 1120px) {
    width: 100%;
    max-height: 300px;
    min-height: 300px;
    padding: 24px;
    ;}   
button {
    max-width: 180px;
}
`

export const LeftBlockText = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
color: #FFF;



h3 {
font-size: 32px;
font-weight: 700;
@media (max-width: 1120px) {
    font-size: 20px;
}

}
p {
font-size: 16px;
font-weight: 400;
@media (max-width: 1120px) {
    font-size: 14px;

}
}
`



export const RightBlock = styled.div`
display: flex;
flex-direction: column;
width: 50%;
padding: 50px;
background: #181B3E;
gap: 24px;
align-items: flex-start;
max-height: 546px;
min-height: 546px;
height: 100%;
button {
    width: 100%;
}
@media (max-width: 1120px) {
    width: 100%;
    padding: 24px;
    max-height: 500px;
min-height: 500px;
}
`

export const RightBlockHeader = styled.h2`
color: #5784F7;
font-size: 12px;
font-weight: 700;
line-height: 120%; /* 14.4px */
letter-spacing: 2.4px;

`

export const RightBlockDesc = styled.h2`
color: #FFF;
font-size: 32px;
font-weight: 700;
@media (max-width: 1120px) {
    font-size: 20px;
}
`

export const RightBlockForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;


export const InputCont = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;

div {
    flex: 1; /* Каждый input занимает равное количество места */
    width: 100%; /* Это гарантирует, что flex: 1 работает корректно */
  }

  .input-error {
    border: 1px solid red;
  }
`;


export const InputMaskCustomBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

export const InputMaskCustom = styled(InputMask)`
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
font-weight: 400;
line-height: 120%;
  background: #181B3E;
  &:focus {
    outline: none;
    border-color: #fff; /* Замените на нужный цвет, если требуется */
  }
`;

export const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

export const TextareaCustom = styled.textarea`
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  background: #181B3E;
  resize: none; /* Убираем возможность изменения размера */
  height: 114px; /* Фиксированная высота */
  
  &:focus {
    outline: none;
    border-color: #fff; /* Замените на нужный цвет, если требуется */
  }
  
  &.input-error {
    border-color: ${colors.WARNING};
  }
`;