import React from 'react';
import { ButtonStyle } from './Button.style';

interface ButtonProps {
  text?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset'; // Добавляем поддержку стандартных типов кнопок

}

export const Button = ({ text, className, onClick, type }: ButtonProps) => {
  return (
    <ButtonStyle className={className} onClick={onClick} type={type}>
      {text}
    </ButtonStyle>
  );
};
